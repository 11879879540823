import { Accordion, useAccordionButton } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuCategories from "./MenuCategories";
import { stubFalse } from "lodash";
import { AiOutlineRight } from 'react-icons/ai'

const ManageMenu = () => {
  let navigate = useNavigate();
  let { MenuID } = useParams();

  //#region pages variables
  const [allPages, setAllPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  //pages functions
  async function GetAllPages() {
    const URL = Variables.API_URL + "Pages/GetPages";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllPages(listItems);
  }

  function handlePages() {
    if (selectedPages.length != 0) {
      var array = [...menuItemsList];

      if (array.length == 0) {
        for (var i = 0; i < selectedPages.length; i++) {
          var obj = {
            ListOf: "pages",
            ItemOrder: i,
            ItemTitle: selectedPages[i].ItemTitle,
            ItemId: selectedPages[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      } else {
        for (var i = 0; i < selectedPages.length; i++) {
          var obj = {
            ListOf: "pages",
            ItemOrder: array[array.length - 1].ItemOrder + 1,
            ItemTitle: selectedPages[i].ItemTitle,
            ItemId: selectedPages[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      }
      setMenuItemsList(array);
      setSelectedPages([]);
    }
  }

  function handleChangePages(e, name, ItemId) {
    var updatedList = [...selectedPages];
    if (e.target.checked) {
      updatedList = [...updatedList, { ItemTitle: name, ItemId: ItemId }];
    } else {
      updatedList = updatedList.filter((item) => item.ItemTitle != name);
    }
    setSelectedPages(updatedList);
  }

  //#endregion

  //#region groups variables
  const [allgroups, setAllgroups] = useState([]);
  const [selectedgroups, setSelectedgroups] = useState([]);

  //groups functions
  async function GetAllGroups() {
    const URL = Variables.API_URL + "Group/GetGroups";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllgroups(listItems);
  }

  function handleGroups() {
    if (selectedgroups.length != 0) {
      var array = [...menuItemsList];

      if (array.length == 0) {
        for (var i = 0; i < selectedgroups.length; i++) {
          var obj = {
            ListOf: "groups",
            ItemOrder: i,
            ItemTitle: selectedgroups[i].ItemTitle,
            ItemId: selectedgroups[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      } else {
        for (var i = 0; i < selectedgroups.length; i++) {
          var obj = {
            ListOf: "groups",
            ItemOrder: array[array.length - 1].ItemOrder + 1,
            ItemTitle: selectedgroups[i].ItemTitle,
            ItemId: selectedgroups[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      }
      setMenuItemsList(array);
      setSelectedgroups([]);
    }
  }

  function handleChangeGroups(e, name, ItemId) {
    console.log(e.target.checked);
    console.log(name);
    console.log(ItemId);
    var updatedList = [...selectedgroups];
    if (e.target.checked) {
      updatedList = [...updatedList, { ItemTitle: name, ItemId: ItemId }];
    } else {
      updatedList = updatedList.filter((item) => item.ItemId != ItemId);
    }
    setSelectedgroups(updatedList);
  }

  //#endregion

  //#region menu

  const [menuTitle, setmenuTitle] = useState("");
  const [menuIsCreated, setMenuIscreated] = useState(false);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [allMenus, setAllMenus] = useState([]);
  const [enableButtons, setEnablebuttons] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSub, setIsSub] = useState(false);
  const [selectedSubItems, setSelectedSubItems] = useState([]);

  async function GetAllMenus() {
    const URL = Variables.API_URL + "Menus/GetMenus";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllMenus(listItems);
  }

  function handleMegaMenu(e, i) {
    var array = [...menuItemsList];


    array.forEach((element, index) => {

      if (i == index) {
        if (element.Sub.length != 0) {
          element.Sub.forEach((sub, ind) => {
            if (sub.ListOf === "groups" && e.target.checked === true) {
              element.MegaMenu = e.target.checked;
            }
            else {
              toast.error("Mega menu only takes a group of categories");
            }
          });

        } else {
          element.MegaMenu = e.target.checked;
        }

      }
    });
    setMenuItemsList(array);
  }
  async function GetMenu() {
    const URL = Variables.API_URL + "Menus/GetMenu/" + MenuID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSelectedMenu(MenuID);
    setmenuTitle(listItems.Title);
  }
  async function GetAssignedItems() {
    const URL = Variables.API_URL + "Menus/GetAssignedItems/" + MenuID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setMenuItemsList(listItems);
  }

  function handleSelectedItems(e, i) {
    var updatedList = [...selectedItems];
    if (e.target.checked) {
      updatedList = [...updatedList, { index: i }];
    } else {
      updatedList = updatedList.filter((item) => item.index != i);
    }
    setSelectedItems(updatedList);
  }

  function handleSelectedSubItems(e, index, i) {
    var updatedList = [...selectedSubItems];
    if (e.target.checked) {
      updatedList = [...updatedList, { parent: i, sub: index }];
    } else {
      // updatedList = updatedList.filter((item) => (item.parent !== i && item.sub!==index));
      updatedList.splice((item) => item.parent == i && item.sub == index, 1);
    }
    setSelectedSubItems(updatedList);
  }

  const handleSubmit = async () => {
    if (MenuID == 0) {
      if (menuTitle === "") {
        document.getElementById("menuTitle").focus();
      } else {
        let response = await fetch(Variables.API_URL + "Menus/AddMenu", {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
          },
          body: JSON.stringify({
            Title: menuTitle,
            IsPublished: false,
          }),
        });
        let result = await response.json();
        if (response.ok) {
          navigate("/Menus/" + result);
          GetMenu();
          GetAllMenus();
          toast.success("Please Select a menu ");
          setMenuIscreated(true);
          setEnablebuttons(true);
        } else {
          toast.error(result);
        }
      }
    } else {
      let response = await fetch(
        Variables.API_URL + "Menus/UpdateMenu/" + MenuID,
        {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
          },
          body: JSON.stringify({
            Title: menuTitle,
          }),
        }
      );
      let result = await response.json();
      if (response.ok) {
        toast.success(result);
        GetMenu();
        GetAllMenus();
        setTimeout(() => {
          navigate("/Menus");
        }, 600);
      } else {
        toast.error(result);
      }
      let categories = [];
      let customLinks = [];
      let pages = [];
      let groups = [];

      for (var i = 0; i < menuItemsList.length; i++) {
        if (menuItemsList[i].ItemOrder != i) {
          menuItemsList[i].ItemOrder = i;
        }
        if (menuItemsList[i].ListOf === "categories") {
          categories.push(menuItemsList[i]);
        }

        if (menuItemsList[i].ListOf === "customLinks") {
          customLinks.push(menuItemsList[i]);
        }
        if (menuItemsList[i].ListOf === "pages") {
          pages.push(menuItemsList[i]);
        }
        if (menuItemsList[i].ListOf === "groups") {
          groups.push(menuItemsList[i]);
        }

        for (var j = 0; j < menuItemsList[i].Sub.length; j++) {
          if (menuItemsList[i].Sub[j].ItemOrder != j) {
            menuItemsList[i].Sub[j].ItemOrder = j;
          }
          if (menuItemsList[i].Sub[j].ListOf === "categories") {
            categories.push(menuItemsList[i].Sub[j]);
          }

          if (menuItemsList[i].Sub[j].ListOf === "customLinks") {
            customLinks.push(menuItemsList[i].Sub[j]);
          }
          if (menuItemsList[i].Sub[j].ListOf === "pages") {
            pages.push(menuItemsList[i].Sub[j]);
          }
          if (menuItemsList[i].Sub[j].ListOf === "groups") {
            groups.push(menuItemsList[i].Sub[j]);
          }
        }
      }
      console.log("categories", categories);
      if (categories.length > 0) {
        let response = await fetch(
          Variables.API_URL + "Menus/AssignCategoriesToMenu/" + MenuID,
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
            body: JSON.stringify(
              categories.map((item) => ({
                CategoryId: item.ItemId,
                MenuId: MenuID,
                ItemOrder: item.ItemOrder,
                CategoryParentId: item.CategoryParentId,
                LinkParentId: item.LinkParentId,
                PageParentId: item.PageParentId,
                GroupParentId: item.GroupParentId,
                MegaMenu: item.MegaMenu,
              }))
            ),
          }
        );
      } else {
        let response = await fetch(
          Variables.API_URL + "Menus/DeleteAssignedCategories/" + MenuID,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
          }
        );
      }

      if (pages.length > 0) {
        let response = await fetch(
          Variables.API_URL + "Menus/AssignPagesToMenu/" + MenuID,
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
            body: JSON.stringify(
              pages.map((item) => ({
                PageId: item.ItemId,
                MenuId: MenuID,
                ItemOrder: item.ItemOrder,
                CategoryParentId: item.CategoryParentId,
                LinkParentId: item.LinkParentId,
                PageParentId: item.PageParentId,
                GroupParentId: item.GroupParentId,
                MegaMenu: item.MegaMenu,
              }))
            ),
          }
        );
      } else {
        let response = await fetch(
          Variables.API_URL + "Menus/DeleteAssignedPages/" + MenuID,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
          }
        );
      }
      if (groups.length > 0) {
        let response = await fetch(
          Variables.API_URL + "Menus/AssignGroupsToMenu/" + MenuID,
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
            body: JSON.stringify(
              groups.map((item) => ({
                GroupId: item.ItemId,
                MenuId: MenuID,
                ItemOrder: item.ItemOrder,
                CategoryParentId: item.CategoryParentId,
                LinkParentId: item.LinkParentId,
                PageParentId: item.PageParentId,
                GroupParentId: item.GroupParentId,
                MegaMenu: item.MegaMenu,
              }))
            ),
          }
        );
      } else {
        let response = await fetch(
          Variables.API_URL + "Menus/DeleteAssignedGroups/" + MenuID,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
          }
        );
      }

      if (customLinks.length > 0) {
        let response2 = await fetch(
          Variables.API_URL + "Menus/AssignCustomLinksToMenu/" + MenuID,
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
            body: JSON.stringify(
              customLinks.map((item) => ({
                CustomLinkId: item.ItemId,
                MenuId: MenuID,
                ItemOrder: item.ItemOrder,
                CategoryParentId: item.CategoryParentId,
                LinkParentId: item.LinkParentId,
                PageParentId: item.PageParentId,
                GroupParentId: item.GroupParentId,
                MegaMenu: item.MegaMenu,
              }))
            ),
          }
        );
      } else {
        let response = await fetch(
          Variables.API_URL + "Menus/DeleteAssignedCustomLinks/" + MenuID,
          {
            method: "Delete",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
            },
          }
        );
      }
    }
  };

  function handleSelectMenu() {
    navigate("/Menus/" + selectedMenu);
    if (selectedMenu != 0) {
      GetMenu();
    }
    setEnablebuttons(true);
    setMenuIscreated(true);
  }

  function handleMoveUpSub(index) {
    var array = [...menuItemsList];
    for (var i = 0; i < array.length; i++) {
      for (var k = 0; k < array[i].Sub.length; k++) {
        if (k == index) {
          var toUpdate = array[i].Sub[index];
          if (toUpdate.ListOf === "categories") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "categories",
              ItemOrder: k - 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index - 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index - 1].ItemTitle,
              ItemId: array[i].Sub[index - 1].ItemId,
              Url: array[i].Sub[index - 1].Url,
              Sub: array[i].Sub[index - 1].Sub,
              CategoryParentId: array[i].Sub[index - 1].CategoryParentId,
              LinkParentId: array[i].Sub[index - 1].LinkParentId,
              PageParentId: array[i].Sub[index - 1].PageParentId,
              GroupParentId: array[i].Sub[index - 1].GroupParentId,
              MegaMenu: array[i].Sub[index - 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index - 1] = obj1;
          }
          if (toUpdate.ListOf === "pages") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "pages",
              ItemOrder: k - 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index - 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index - 1].ItemTitle,
              ItemId: array[i].Sub[index - 1].ItemId,
              Url: array[i].Sub[index - 1].Url,
              Sub: array[i].Sub[index - 1].Sub,
              CategoryParentId: array[i].Sub[index - 1].CategoryParentId,
              LinkParentId: array[i].Sub[index - 1].LinkParentId,
              PageParentId: array[i].Sub[index - 1].PageParentId,
              GroupParentId: array[i].Sub[index - 1].GroupParentId,
              MegaMenu: array[i].Sub[index - 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index - 1] = obj1;
          }
          if (toUpdate.ListOf === "groups") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "groups",
              ItemOrder: k - 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index - 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index - 1].ItemTitle,
              ItemId: array[i].Sub[index - 1].ItemId,
              Url: array[i].Sub[index - 1].Url,
              Sub: array[i].Sub[index - 1].Sub,
              CategoryParentId: array[i].Sub[index - 1].CategoryParentId,
              LinkParentId: array[i].Sub[index - 1].LinkParentId,
              PageParentId: array[i].Sub[index - 1].PageParentId,
              GroupParentId: array[i].Sub[index - 1].GroupParentId,
              MegaMenu: array[i].Sub[index - 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index - 1] = obj1;
          }

          if (toUpdate.ListOf === "customLinks") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "customLinks",
              ItemOrder: k - 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index - 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index - 1].ItemTitle,
              ItemId: array[i].Sub[index - 1].ItemId,
              Url: array[i].Sub[index - 1].Url,
              Sub: array[i].Sub[index - 1].Sub,
              CategoryParentId: array[i].Sub[index - 1].CategoryParentId,
              LinkParentId: array[i].Sub[index - 1].LinkParentId,
              PageParentId: array[i].Sub[index - 1].PageParentId,
              GroupParentId: array[i].Sub[index - 1].GroupParentId,
              MegaMenu: array[i].Sub[index - 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index - 1] = obj1;
          }
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleMoveDownSub(index) {
    var array = [...menuItemsList];
    for (var i = 0; i < array.length; i++) {
      for (var k = 0; k < array[i].Sub.length; k++) {
        if (k == index) {
          var toUpdate = array[i].Sub[index];
          if (toUpdate.ListOf === "categories") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "categories",
              ItemOrder: k + 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index + 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index + 1].ItemTitle,
              ItemId: array[i].Sub[index + 1].ItemId,
              Url: array[i].Sub[index + 1].Url,
              Sub: array[i].Sub[index + 1].Sub,
              CategoryParentId: array[i].Sub[index + 1].CategoryParentId,
              LinkParentId: array[i].Sub[index + 1].LinkParentId,
              PageParentId: array[i].Sub[index + 1].PageParentId,
              GroupParentId: array[i].Sub[index + 1].GroupParentId,
              MegaMenu: array[i].Sub[index + 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index + 1] = obj1;
          }
          if (toUpdate.ListOf === "pages") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "pages",
              ItemOrder: k + 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.URL,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index + 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index + 1].ItemTitle,
              ItemId: array[i].Sub[index + 1].ItemId,
              Url: array[i].Sub[index + 1].Url,
              Sub: array[i].Sub[index + 1].Sub,
              CategoryParentId: array[i].Sub[index + 1].CategoryParentId,
              LinkParentId: array[i].Sub[index + 1].LinkParentId,
              PageParentId: array[i].Sub[index + 1].PageParentId,
              GroupParentId: array[i].Sub[index + 1].GroupParentId,
              MegaMenu: array[i].Sub[index + 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index + 1] = obj1;
          }
          if (toUpdate.ListOf === "groups") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "groups",
              ItemOrder: k + 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.URL,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index + 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index + 1].ItemTitle,
              ItemId: array[i].Sub[index + 1].ItemId,
              Url: array[i].Sub[index + 1].Url,
              Sub: array[i].Sub[index + 1].Sub,
              CategoryParentId: array[i].Sub[index + 1].CategoryParentId,
              LinkParentId: array[i].Sub[index + 1].LinkParentId,
              PageParentId: array[i].Sub[index + 1].PageParentId,
              GroupParentId: array[i].Sub[index + 1].GroupParentId,
              MegaMenu: array[i].Sub[index + 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index + 1] = obj1;
          }
          if (toUpdate.ListOf === "customLinks") {
            delete array[i].Sub[index];
            var obj1 = {
              ListOf: "customLinks",
              ItemOrder: k + 1,
              ItemTitle: toUpdate.ItemTitle,
              ItemId: toUpdate.ItemId,
              Url: toUpdate.Url,
              Sub: toUpdate.Sub,
              CategoryParentId: toUpdate.CategoryParentId,
              LinkParentId: toUpdate.LinkParentId,
              PageParentId: toUpdate.PageParentId,
              GroupParentId: toUpdate.GroupParentId,
              MegaMenu: toUpdate.MegaMenu,
            };
            var obj2 = {
              ListOf: array[i].Sub[index + 1].ListOf,
              ItemOrder: k,
              ItemTitle: array[i].Sub[index + 1].ItemTitle,
              ItemId: array[i].Sub[index + 1].ItemId,
              Url: array[i].Sub[index + 1].Url,
              Sub: array[i].Sub[index + 1].Sub,
              CategoryParentId: array[i].Sub[index + 1].CategoryParentId,
              LinkParentId: array[i].Sub[index + 1].LinkParentId,
              PageParentId: array[i].Sub[index + 1].PageParentId,
              GroupParentId: array[i].Sub[index + 1].GroupParentId,
              MegaMenu: array[i].Sub[index + 1].MegaMenu,
            };
            array[i].Sub[index] = obj2;
            array[i].Sub[index + 1] = obj1;
          }
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleMoveUp(index) {
    var array = [...menuItemsList];
    var toUpdate = array[index];
    for (var i = 0; i < array.length; i++) {
      if (i == index) {
        if (toUpdate.ListOf === "categories") {
          delete array[i];
          var obj1 = {
            ListOf: "categories",
            ItemOrder: i - 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i - 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i - 1].ItemTitle,
            ItemId: array[i - 1].ItemId,
            Url: "",
            Sub: array[i - 1].Sub,
            CategoryParentId: array[i - 1].CategoryParentId,
            LinkParentId: array[i - 1].LinkParentId,
            PageParentId: array[i - 1].PageParentId,
            GroupParentId: array[i - 1].GroupParentId,
            MegaMenu: array[i - 1].MegaMenu,
          };
          array[i] = obj2;
          array[i - 1] = obj1;
        }
        if (toUpdate.ListOf === "customLinks") {
          delete array[i];
          var obj1 = {
            ListOf: "customLinks",
            ItemOrder: i - 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i - 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i - 1].ItemTitle,
            ItemId: array[i - 1].ItemId,
            Url: "",
            Sub: array[i - 1].Sub,
            CategoryParentId: array[i - 1].CategoryParentId,
            LinkParentId: array[i - 1].LinkParentId,
            PageParentId: array[i - 1].PageParentId,
            GroupParentId: array[i - 1].GroupParentId,
            MegaMenu: array[i - 1].MegaMenu,
          };
          array[i] = obj2;
          array[i - 1] = obj1;
        }
        if (toUpdate.ListOf === "pages") {
          delete array[i];
          var obj1 = {
            ListOf: "pages",
            ItemOrder: i - 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i - 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i - 1].ItemTitle,
            ItemId: array[i - 1].ItemId,
            Url: "",
            Sub: array[i - 1].Sub,
            CategoryParentId: array[i - 1].CategoryParentId,
            LinkParentId: array[i - 1].LinkParentId,
            PageParentId: array[i - 1].PageParentId,
            GroupParentId: array[i - 1].GroupParentId,
            MegaMenu: array[i - 1].MegaMenu,
          };
          array[i] = obj2;
          array[i - 1] = obj1;
        }
        if (toUpdate.ListOf === "groups") {
          delete array[i];
          var obj1 = {
            ListOf: "groups",
            ItemOrder: i - 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i - 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i - 1].ItemTitle,
            ItemId: array[i - 1].ItemId,
            Url: "",
            Sub: array[i - 1].Sub,
            CategoryParentId: array[i - 1].CategoryParentId,
            LinkParentId: array[i - 1].LinkParentId,
            PageParentId: array[i - 1].PageParentId,
            GroupParentId: array[i - 1].GroupParentId,
            MegaMenu: array[i - 1].MegaMenu,
          };
          array[i] = obj2;
          array[i - 1] = obj1;
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleMakeSub(index) {
    var array = [...menuItemsList];
    var subToUpdate = array[index - 1].Sub;
    var arrayToDelete = array[index];
    var deleteobj = true;
    for (var i = 0; i < array.length; i++) {
      if (i == index) {
        if (array[i - 1].Sub.length == 0) {
          if (array[i - 1].ListOf === "categories") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: 0,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: array[i - 1].ItemId,
              LinkParentId: 0,
              PageParentId: 0,
              GroupParentId: 0,
              MegaMenu: false,
            };
            array[i - 1].Sub.push(newobj);
          }
          if (array[i - 1].ListOf === "pages") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: 0,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: 0,
              LinkParentId: 0,
              PageParentId: array[i - 1].ItemId,
              GroupParentId: 0,
              MegaMenu: false,
            };
            array[i - 1].Sub.push(newobj);
          }
          if (array[i - 1].ListOf === "groups") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: 0,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: 0,
              LinkParentId: 0,
              PageParentId: 0,
              GroupParentId: array[i - 1].ItemId,
              MegaMenu: false,
            };
            array[i - 1].Sub.push(newobj);
          }
          if (array[i - 1].ListOf === "customLinks") {
            if (array[i - 1].MegaMenu == true) {
              if (array[i].ListOf === "groups") {
                var newobj = {
                  ListOf: arrayToDelete.ListOf,
                  ItemOrder: 0,
                  ItemTitle: arrayToDelete.ItemTitle,
                  ItemId: arrayToDelete.ItemId,
                  Url: arrayToDelete.Url,
                  Sub: [],
                  CategoryParentId: 0,
                  LinkParentId: array[i - 1].ItemId,
                  PageParentId: 0,
                  GroupParentId: 0,
                  MegaMenu: false,
                };
                array[i - 1].Sub.push(newobj);
              }
              else {
                deleteobj = false;
                toast.error("Mega menu only takes a group of categories");
              }
            }
            else {
              var newobj = {
                ListOf: arrayToDelete.ListOf,
                ItemOrder: 0,
                ItemTitle: arrayToDelete.ItemTitle,
                ItemId: arrayToDelete.ItemId,
                Url: arrayToDelete.Url,
                Sub: [],
                CategoryParentId: 0,
                LinkParentId: array[i - 1].ItemId,
                PageParentId: 0,
                GroupParentId: 0,
                MegaMenu: false,
              };
              array[i - 1].Sub.push(newobj);
            }

          }
        } else {
          if (array[i - 1].ListOf === "categories") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: arrayToDelete.ItemOrder + 1,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: array[i - 1].ItemId,
              LinkParentId: 0,
              PageParentId: 0,
              GroupParentId: 0,
              MegaMenu: false,
            };
            subToUpdate.push(newobj);
          }
          if (array[i - 1].ListOf === "pages") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: arrayToDelete.ItemOrder + 1,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: 0,
              LinkParentId: 0,
              PageParentId: array[i - 1].ItemId,
              GroupParentId: 0,
              MegaMenu: false,
            };
            subToUpdate.push(newobj);
          }
          if (array[i - 1].ListOf === "groups") {
            var newobj = {
              ListOf: arrayToDelete.ListOf,
              ItemOrder: arrayToDelete.ItemOrder + 1,
              ItemTitle: arrayToDelete.ItemTitle,
              ItemId: arrayToDelete.ItemId,
              Url: arrayToDelete.Url,
              Sub: [],
              CategoryParentId: 0,
              LinkParentId: 0,
              PageParentId: 0,
              GroupParentId: array[i - 1].ItemId,
              MegaMenu: false,
            };
            subToUpdate.push(newobj);
          }
          if (array[i - 1].ListOf === "customLinks") {
            if (array[i - 1].MegaMenu == true) {
              if (array[i].ListOf === "groups") {
                var newobj = {
                  ListOf: arrayToDelete.ListOf,
                  ItemOrder: arrayToDelete.ItemOrder + 1,
                  ItemTitle: arrayToDelete.ItemTitle,
                  ItemId: arrayToDelete.ItemId,
                  Url: arrayToDelete.Url,
                  Sub: [],
                  CategoryParentId: 0,
                  LinkParentId: array[i - 1].ItemId,
                  PageParentId: 0,
                  GroupParentId: 0,
                  MegaMenu: false,
                };
                subToUpdate.push(newobj);
              } else {
                deleteobj = false;
                toast.error("Mega menu only takes a group of categories");

              }
            } else {
              var newobj = {
                ListOf: arrayToDelete.ListOf,
                ItemOrder: arrayToDelete.ItemOrder + 1,
                ItemTitle: arrayToDelete.ItemTitle,
                ItemId: arrayToDelete.ItemId,
                Url: arrayToDelete.Url,
                Sub: [],
                CategoryParentId: 0,
                LinkParentId: array[i - 1].ItemId,
                PageParentId: 0,
                GroupParentId: 0,
                MegaMenu: false,
              };
              subToUpdate.push(newobj);
            }

          }
        }
        if (deleteobj == true) {
          delete array[i];
        }
      }
      if (array[i] === undefined) {
        array.splice(i, 1);
      }
    }
    setMenuItemsList(array);
  }

  function handleRemoveSub(index) {
    var array = [...menuItemsList];
    for (var i = 0; i < array.length; i++) {
      for (var k = 0; k < array[i].Sub.length; k++) {
        if (k === index) {
          var obj = {
            ListOf: array[i].Sub[k].ListOf,
            ItemOrder: array[i].Sub[k].ItemOrder + 1,
            ItemTitle: array[i].Sub[k].ItemTitle,
            ItemId: array[i].Sub[k].ItemId,
            Url: array[i].Sub[k].Url,
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
          array[i].Sub.splice(k, 1);
        }
      }
    }

    setMenuItemsList(array);
  }

  function handleMoveDown(index) {
    var array = [...menuItemsList];
    var toUpdate = array[index];
    for (var i = 0; i < array.length; i++) {
      if (i == index) {
        if (toUpdate.ListOf === "categories") {
          delete array[i];
          var obj1 = {
            ListOf: "categories",
            ItemOrder: i + 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i + 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i + 1].ItemTitle,
            ItemId: array[i + 1].ItemId,
            Url: "",
            Sub: array[i + 1].Sub,
            CategoryParentId: array[i + 1].CategoryParentId,
            LinkParentId: array[i + 1].LinkParentId,
            PageParentId: array[i + 1].PageParentId,
            GroupParentId: array[i + 1].GroupParentId,
            MegaMenu: array[i + 1].MegaMenu,
          };
          array[i] = obj2;
          array[i + 1] = obj1;
        }
        if (toUpdate.ListOf === "customLinks") {
          delete array[i];
          var obj1 = {
            ListOf: "customLinks",
            ItemOrder: i + 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i + 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i + 1].ItemTitle,
            ItemId: array[i + 1].ItemId,
            Url: "",
            Sub: array[i + 1].Sub,
            CategoryParentId: array[i + 1].CategoryParentId,
            LinkParentId: array[i + 1].LinkParentId,
            PageParentId: array[i + 1].PageParentId,
            GroupParentId: array[i + 1].GroupParentId,
            MegaMenu: array[i + 1].MegaMenu,
          };
          array[i] = obj2;
          array[i + 1] = obj1;
        }
        if (toUpdate.ListOf === "pages") {
          delete array[i];
          var obj1 = {
            ListOf: "pages",
            ItemOrder: i + 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i + 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i + 1].ItemTitle,
            ItemId: array[i + 1].ItemId,
            Url: "",
            Sub: array[i + 1].Sub,
            CategoryParentId: array[i + 1].CategoryParentId,
            LinkParentId: array[i + 1].LinkParentId,
            PageParentId: array[i + 1].PageParentId,
            GroupParentId: array[i + 1].GroupParentId,
            MegaMenu: array[i + 1].MegaMenu,
          };
          array[i] = obj2;
          array[i + 1] = obj1;
        }
        if (toUpdate.ListOf === "groups") {
          delete array[i];
          var obj1 = {
            ListOf: "groups",
            ItemOrder: i + 1,
            ItemTitle: toUpdate.ItemTitle,
            ItemId: toUpdate.ItemId,
            Url: "",
            Sub: toUpdate.Sub,
            CategoryParentId: toUpdate.CategoryParentId,
            LinkParentId: toUpdate.LinkParentId,
            PageParentId: toUpdate.PageParentId,
            GroupParentId: toUpdate.GroupParentId,
            MegaMenu: toUpdate.MegaMenu,
          };
          var obj2 = {
            ListOf: array[i + 1].ListOf,
            ItemOrder: i,
            ItemTitle: array[i + 1].ItemTitle,
            ItemId: array[i + 1].ItemId,
            Url: "",
            Sub: array[i + 1].Sub,
            CategoryParentId: array[i + 1].CategoryParentId,
            LinkParentId: array[i + 1].LinkParentId,
            PageParentId: array[i + 1].PageParentId,
            GroupParentId: array[i + 1].GroupParentId,
            MegaMenu: array[i + 1].MegaMenu,
          };
          array[i] = obj2;
          array[i + 1] = obj1;
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleBulkSelect() {
    setBulkSelect(!bulkSelect);
  }
  function handleDeleteMenu() {
    fetch(Variables.API_URL + "Menus/DeleteMenu/" + MenuID, {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
      },
    }).then((response) => {
      if (response.ok) {
        window.location.href = "/Menus/0";
      }
    });
  }

  function handleNavigationLabel(e, index) {
    let array = [...menuItemsList];
    var objToUpdate = array[index];
    for (var i = 0; i < array.length; i++) {
      if (index == i) {
        delete array[i];
        var newobjToUpdate = {
          ListOf: objToUpdate.ListOf,
          ItemOrder: index,
          ItemTitle: e.target.value,
          ItemId: objToUpdate.ItemId,
          Url: objToUpdate.Url,
          Sub: objToUpdate.Sub,
          CategoryParentId: objToUpdate.CategoryParentId,
          PageParentId: objToUpdate.PageParentId,
          LinkParentId: objToUpdate.LinkId,
          GroupParentId: objToUpdate.GroupParentId,
          MegaMenu: objToUpdate.MegaMenu,
        };
        array[i] = newobjToUpdate;
      }
    }
    setMenuItemsList(array);
  }
  function handleNavigationLabelSub(e, index) {
    let array = [...menuItemsList];
    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].Sub.length; j++) {
        if (index == j) {
          var objToUpdate = array[i].Sub[j];
          delete array[i].Sub[j];
          var newobjToUpdate = {
            ListOf: objToUpdate.ListOf,
            ItemOrder: index,
            ItemTitle: e.target.value,
            ItemId: objToUpdate.ItemId,
            Url: objToUpdate.Url,
            Sub: objToUpdate.Sub,
            CategoryParentId: objToUpdate.CategoryParentId,
            PageParentId: objToUpdate.PageParentId,
            LinkParentId: objToUpdate.LinkId,
            GroupParentId: objToUpdate.GroupParentId,
            MegaMenu: objToUpdate.MegaMenu,
          };
          array[i].Sub[j] = newobjToUpdate;
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleCustomUrl(e, index) {
    let array = [...menuItemsList];
    var objToUpdate = array[index];
    for (var i = 0; i < array.length; i++) {
      if (index == i) {
        delete array[i];
        var newobjToUpdate = {
          ListOf: objToUpdate.ListOf,
          ItemOrder: index,
          ItemTitle: objToUpdate.ItemTitle,
          ItemId: objToUpdate.ItemId,
          Url: e.target.value,
          Sub: objToUpdate.Sub,
          CategoryParentId: objToUpdate.CategoryParentId,
          PageParentId: objToUpdate.PageParentId,
          LinkParentId: objToUpdate.LinkId,
          GroupParentId: objToUpdate.GroupParentId,
          MegaMenu: objToUpdate.MegaMenu,
        };
        array[i] = newobjToUpdate;
      }
    }
    setMenuItemsList(array);
  }

  function handleCustomUrlSub(e, index) {
    let array = [...menuItemsList];

    for (var i = 0; i < array.length; i++) {
      for (var j = 0; j < array[i].Sub.length; j++) {
        if (index == j) {
          var objToUpdate = array[i].Sub[j];
          delete array[i].Sub[j];
          var newobjToUpdate = {
            ListOf: objToUpdate.ListOf,
            ItemOrder: index,
            ItemTitle: objToUpdate.ItemTitle,
            ItemId: objToUpdate.ItemId,
            Url: e.target.value,
            Sub: objToUpdate.Sub,
            CategoryParentId: objToUpdate.CategoryParentId,
            PageParentId: objToUpdate.PageParentId,
            LinkParentId: objToUpdate.LinkId,
            GroupParentId: objToUpdate.GroupParentId,
            MegaMenu: objToUpdate.MegaMenu,
          };
          array[i].Sub[j] = newobjToUpdate;
        }
      }
    }
    setMenuItemsList(array);
  }

  function handleRemoveItem(i) {
    if (menuItemsList.length == 1) {
      setMenuItemsList([]);
    } else {
      var array = [...menuItemsList];
      for (var j = 0; j < array.length; j++) {
        if (i == j) {
          //  array=array.filter((item=>item.ItemOrder!=i))
          array = array.filter((item, index) => index != i);
        }
      }
      setMenuItemsList(array);
    }
  }

  function handleRemoveSubItem(i, index) {
    var array = [...menuItemsList];
    for (var j = 0; j < array.length; j++) {
      for (var k = 0; k < array[j].Sub.length; k++) {
        if (index == k && j == i) {
          if (array[j].Sub.length == 1) {
            array[j].Sub = [];
          } else {
            array[j].Sub.splice(index, 1);
          }
        }
      }
    }

    setMenuItemsList(array);
  }

  function handleRemoveItems() {
    var array = [...menuItemsList];
    var selectedParents = [...selectedItems];
    var selectedSub = [...selectedSubItems];
    if (selectedParents.length > 0) {
      for (var i = 0; i < selectedParents.length; i++) {
        delete array[i];
      }
    }

    if (selectedSub.length > 0) {
      for (var i = 0; i < selectedSub.length; i++) {
        const parent = selectedSub[i].parent;
        const sub = selectedSub[i].sub;
        for (var s = 0; s < array.length; s++) {
          if (s === parent) {
            for (var x = 0; x < array[s].Sub.length; x++) {
              if (x === sub) {
                delete array[s].Sub[x];
              }
              if (array[s].Sub[x] === undefined) {
                array[s].Sub.splice(x);
              }
            }
          }
        }
      }
    }
    for (var index = 0; index < array.length; index++) {
      if (array[index] === undefined) {
        array.splice(index, 1);
        index--;
      }
    }

    setMenuItemsList(array);
    setBulkSelect(false);
    setSelectedItems([]);
    setSelectedSubItems([]);
  }

  //#endregion

  //#region custom links
  const [Url, setUrl] = useState("");
  const [link, setLink] = useState("");

  async function handleCustomLinks() {
    if (Url === "") {
      toast.error("Please Fill Required Fields");
      document.getElementById("Url").focus();
    } else if (link === "") {
      toast.error("Please Fill Required Fields");
      document.getElementById("link").focus();
    } else {
      let response = await fetch(
        Variables.API_URL + "CustomLink/AddCustomLink",
        {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Url: Url,
            LinkText: link,
          }),
        }
      );
      let result = await response.json();
      if (response.status == 200) {
        var array = [...menuItemsList];
        if (array.length == 0) {
          var obj = {
            ListOf: "customLinks",
            ItemOrder: 0,
            ItemTitle: link,
            ItemId: result,
            Url: Url,
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
        } else {
          var obj = {
            ListOf: "customLinks",
            ItemOrder: array[array.length - 1].ItemOrder + 1,
            ItemTitle: link,
            ItemId: result,
            Url: Url,
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
        }
        array.push(obj);
        setMenuItemsList(array);
        setUrl("");
        setLink("");
      } else {
        toast.error(result);
      }
    }
  }

  //#endregion

  //#region categories
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  function handleCategories() {
    if (selectedCategories.length != 0) {
      var array = [...menuItemsList];

      if (array.length == 0) {
        for (var i = 0; i < selectedCategories.length; i++) {
          var obj = {
            ListOf: "categories",
            ItemOrder: i,
            ItemTitle: selectedCategories[i].ItemTitle,
            ItemId: selectedCategories[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      } else {
        for (var i = 0; i < selectedCategories.length; i++) {
          var obj = {
            ListOf: "categories",
            ItemOrder: array[array.length - 1].ItemOrder + 1,
            ItemTitle: selectedCategories[i].ItemTitle,
            ItemId: selectedCategories[i].ItemId,
            Url: "",
            Sub: [],
            CategoryParentId: 0,
            LinkParentId: 0,
            PageParentId: 0,
            GroupParentId: 0,
            MegaMenu: false,
          };
          array.push(obj);
        }
      }
      setMenuItemsList(array);
      setSelectedCategories([]);
    }
  }

  //#endregion

  useEffect(() => {
    GetAllMenus();
    GetAllPages();
    GetAllGroups();
    // GetAllPosts()
    if (MenuID != 0) {
      GetAssignedItems();
      GetMenu();
      setEnablebuttons(true);
      setMenuIscreated(true);
    }
  }, [MenuID]);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>Manage Menu: {menuTitle}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Menus">Menus <AiOutlineRight /></Link></li>
              <li>Manage Menu</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-2">
                        <label>Select a menu to edit: </label>
                      </div>
                      <div className="col-2">
                        <select
                          className="form-control"
                          onChange={(e) => setSelectedMenu(e.target.value)}
                          value={selectedMenu}
                        >
                          <option key="0" value="0">
                            ---Select---
                          </option>
                          {allMenus.map((x) => (
                            <option key={x.MenuId} value={x.MenuId}>
                              {x.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary ms-2"
                          onClick={handleSelectMenu}
                        >
                          Select
                        </button>
                      </div>
                      <div className="col-6 text-end">

                        <label> or</label>{" "}
                        <a href="/Menus/0" style={{color: "#c38bb8"}} className="me-2 ms-2">
                          {" "}
                          create a new menu.
                        </a>
                        <label>
                          Don’t forget to save your changes! Click the Save Menu
                          button to save your changes.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="row">
                  <label>Add menu items</label>
                </div>
                <div className="row">
                  <Accordion className="accordion">
                    <Accordion.Item eventKey="pages">
                      <Accordion.Header>Pages</Accordion.Header>
                      <Accordion.Body>
                        {allPages.map((p) => {
                          return (
                            <>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={p.PageId}
                                  itemID="flexCheckDefault"
                                  checked={
                                    selectedPages.filter(
                                      (value) => value.ItemTitle === p.Title
                                    ).length != 0
                                      ? selectedPages.filter(
                                        (value) => value.ItemTitle === p.Title
                                      )[0].Title
                                      : 0
                                  }
                                  onChange={(e) =>
                                    handleChangePages(e, p.Title, p.PageId)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  {p.Title}
                                </label>
                              </div>
                            </>
                          );
                        })}
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-8">
                            {enableButtons ? (
                              <button
                                className="btn btn-outline-primary float-end"
                                onClick={handlePages}
                              >
                                Add To Menu
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="groups">
                      <Accordion.Header>Groups</Accordion.Header>
                      <Accordion.Body>
                        {allgroups.map((p) => {
                          return (
                            <>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={p.GroupId}
                                  itemID="flexCheckDefault"
                                  checked={
                                    selectedgroups.filter(
                                      (value) => value.ItemTitle === p.GroupName
                                    ).length != 0
                                      ? selectedgroups.filter(
                                        (value) =>
                                          value.ItemTitle === p.GroupName
                                      )[0].GroupName
                                      : 0
                                  }
                                  onChange={(e) =>
                                    handleChangeGroups(
                                      e,
                                      p.GroupName,
                                      p.GroupId
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  {p.GroupName}
                                </label>
                              </div>
                            </>
                          );
                        })}
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-6">
                            {enableButtons ? (
                              <button
                                className="btn btn-outline-primary float-end"
                                onClick={handleGroups}
                              >
                                Add To Menu
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="categories">
                      <Accordion.Header>Categories</Accordion.Header>
                      <Accordion.Body>
                        <MenuCategories
                          categories={categories}
                          setCategories={setCategories}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                        />
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-6">
                            {enableButtons ? (
                              <button
                                className="btn btn-outline-primary float-end"
                                onClick={handleCategories}
                              >
                                Add To Menu
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="customeLinks">
                      <Accordion.Header>Custom Links</Accordion.Header>
                      <Accordion.Body>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-4">
                              <label> URL *</label>
                            </div>
                            <div className="col-8">
                              <input
                                type="text"
                                value={Url}
                                name="Url"
                                itemID="Url"
                                onChange={(e) => setUrl(e.target.value)}
                                className="form-control"
                                placeholder="https://"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <label> Link Text *</label>
                            </div>
                            <div className="col-8">
                              <input
                                type="text"
                                value={link}
                                name="link"
                                itemID="link"
                                onChange={(e) => setLink(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4"></div>
                            <div className="col-6">
                              {enableButtons ? (
                                <button
                                  className="btn btn-outline-primary float-end"
                                  onClick={handleCustomLinks}
                                  name="customLinksButton"
                                >
                                  Add To Menu
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <div className="col-9">
                <div className="row">
                  <label>Menu Structure</label>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-2">
                              <label> Menu Name</label>
                            </div>
                            <div className="col-4">
                              <input
                                type="text"
                                value={menuTitle}
                                itemID="menuTitle"
                                onChange={(e) => setmenuTitle(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        {!menuIsCreated ? (
                          <div className="row">
                            <label>
                              Give your menu a name, then click Create Menu.
                            </label>
                          </div>
                        ) : null}
                        {menuIsCreated ? (
                          <>
                            <div className="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  itemID="bulkSelect"
                                  value={bulkSelect}
                                  onChange={handleBulkSelect}
                                  checked={bulkSelect}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="bulkSelect"
                                >
                                  Bulk Select
                                </label>
                              </div>
                            </div>
                            {menuItemsList.length == 0 ? (
                              <div className="row">
                                <label>
                                  {" "}
                                  Add menu items from the column on the left.
                                </label>
                              </div>
                            ) : (
                              <>
                                <label>
                                  Drag the items into the order you prefer.
                                  Click the arrow on the right of the item to
                                  reveal additional configuration options.
                                </label>

                                {/* <OrderGroup > */}

                                {menuItemsList.map((c, i) => {
                                  return (
                                    <>
                                      <div className="col-12 drag" key={i}>
                                        <div className="row"></div>
                                        <div className="row">
                                          <div className={"col-6"}>
                                            <Accordion className="accordion">
                                              <Accordion.Item eventKey={i}>
                                                <Accordion.Header>
                                                  {bulkSelect ? (
                                                    <div className="form-check">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        itemID="accordionCheck"
                                                        value={i}
                                                        onChange={(e) =>
                                                          handleSelectedItems(
                                                            e,
                                                            i
                                                          )
                                                        }
                                                        checked={
                                                          selectedItems.filter(
                                                            (value) =>
                                                              value.index == i
                                                          ).length != 0
                                                            ? selectedItems.filter(
                                                              (value) =>
                                                                value.index ===
                                                                i
                                                            )[0].Title
                                                            : 0
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="accordionCheck"
                                                  >
                                                    {c.ItemTitle}
                                                    {" /  "}
                                                  </label>{" "}
                                                  <label className="text-right">
                                                    {" "}
                                                    {c.ListOf === "categories"
                                                      ? " Category"
                                                      : null}{" "}
                                                    {c.ListOf === "pages"
                                                      ? " Page"
                                                      : null}{" "}
                                                    {c.ListOf === "customLinks"
                                                      ? " Custom Link"
                                                      : null}{" "}
                                                    {c.ListOf === "groups"
                                                      ? " Groups"
                                                      : null}{" "}
                                                  </label>
                                                  <div className="col-4"></div>
                                                  {c.ListOf ===
                                                    "customLinks" && (
                                                      <div className="col-3">
                                                        <div className="form-check">
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="MegaMenu"
                                                          >
                                                            Mega Menu?
                                                          </label>
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={c.MegaMenu}
                                                            onChange={(e) =>
                                                              handleMegaMenu(e, i)
                                                            }
                                                            checked={c.MegaMenu}
                                                            id="MegaMenu"
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <label>
                                                    Navigation Label
                                                  </label>
                                                  <div className="col-12">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      value={c.ItemTitle}
                                                      onChange={(e) =>
                                                        handleNavigationLabel(
                                                          e,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  {c.ListOf ===
                                                    "customLinks" ? (
                                                    <div>
                                                      <label> Url</label>
                                                      <div className=" col-12">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          value={c.Url}
                                                          onChange={(e) =>
                                                            handleCustomUrl(
                                                              e,
                                                              i
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                  <div className="row">
                                                    <label>
                                                      Move
                                                      {i == 0 ? (
                                                        <button
                                                          className="btn btn-link"
                                                          disabled
                                                        >
                                                          Up one
                                                        </button>
                                                      ) : (
                                                        <button
                                                          className="btn btn-link"
                                                          onClick={() =>
                                                            handleMoveUp(i)
                                                          }
                                                        >
                                                          Up one
                                                        </button>
                                                      )}
                                                      {i ==
                                                        menuItemsList.length -
                                                        1 ? (
                                                        <button
                                                          className="btn btn-link"
                                                          disabled
                                                        >
                                                          Down one
                                                        </button>
                                                      ) : (
                                                        <button
                                                          className="btn btn-link"
                                                          onClick={() =>
                                                            handleMoveDown(i)
                                                          }
                                                        >
                                                          Down one
                                                        </button>
                                                      )}
                                                      {i != 0 &&
                                                        menuItemsList[i].Sub
                                                          .length == 0 &&
                                                        <button
                                                          className="btn btn-link"
                                                          onClick={() =>
                                                            handleMakeSub(i)
                                                          }
                                                        >
                                                          Make Sub of{" "}
                                                          {
                                                            menuItemsList[i - 1]
                                                              .ItemTitle
                                                          }
                                                        </button>
                                                      }

                                                    </label>
                                                  </div>
                                                  <div className="row">
                                                    <label>
                                                      <button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                          handleRemoveItem(i)
                                                        }
                                                      >
                                                        Remove
                                                      </button>
                                                    </label>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Accordion>
                                          </div>
                                        </div>
                                      </div>

                                      {c.Sub.map((sub, index) => {
                                        return (
                                          <div className="col-12 drag" key={i}>
                                            <div className="row"></div>
                                            <div className="row">
                                              <div
                                                className={"col-6 slideRight"}
                                              >
                                                <Accordion className="accordion">
                                                  <Accordion.Item
                                                    eventKey={index}
                                                  >
                                                    <Accordion.Header>
                                                      {bulkSelect ? (
                                                        <div className="form-check">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            itemID="accordionCheck"
                                                            value={index}
                                                            onChange={(e) =>
                                                              handleSelectedSubItems(
                                                                e,
                                                                index,
                                                                i
                                                              )
                                                            }
                                                            checked={
                                                              selectedSubItems.filter(
                                                                (value) =>
                                                                  value.sub ==
                                                                  index &&
                                                                  value.parent ==
                                                                  i
                                                              ).length != 0
                                                                ? selectedSubItems.filter(
                                                                  (value) =>
                                                                    value.sub ==
                                                                    index &&
                                                                    value.parent ==
                                                                    i
                                                                )[0].Title
                                                                : 0
                                                            }
                                                          />
                                                        </div>
                                                      ) : null}
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="accordionCheck"
                                                      >
                                                        {sub.ItemTitle}
                                                        {" /"}
                                                      </label>{" "}
                                                      <label className="text-right">
                                                        {" "}
                                                        {sub.ListOf ===
                                                          "categories"
                                                          ? " Category"
                                                          : null}{" "}
                                                        {sub.ListOf === "pages"
                                                          ? " Page"
                                                          : null}{" "}
                                                        {sub.ListOf ===
                                                          "customLinks"
                                                          ? " Custom Link"
                                                          : null}{" "}
                                                        {sub.ListOf === "groups"
                                                          ? " Group"
                                                          : null}{" "}
                                                      </label>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                      <label>
                                                        Navigation Label
                                                      </label>
                                                      <div className="col-12">
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          value={sub.ItemTitle}
                                                          onChange={(e) =>
                                                            handleNavigationLabelSub(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      {sub.ListOf ===
                                                        "customLinks" ? (
                                                        <div>
                                                          <label> Url</label>
                                                          <div className=" col-12">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              value={sub.Url}
                                                              onChange={(e) =>
                                                                handleCustomUrlSub(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : null}

                                                      <div className="row">
                                                        <label>
                                                          Move
                                                          {index == 0 ? (
                                                            <button
                                                              className="btn btn-link"
                                                              disabled
                                                            >
                                                              Up one
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className="btn btn-link"
                                                              onClick={() =>
                                                                handleMoveUpSub(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              Up one
                                                            </button>
                                                          )}
                                                          {index ==
                                                            c.Sub.length - 1 ? (
                                                            <button
                                                              className="btn btn-link"
                                                              disabled
                                                            >
                                                              Down one
                                                            </button>
                                                          ) : (
                                                            <button
                                                              className="btn btn-link"
                                                              onClick={() =>
                                                                handleMoveDownSub(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              Down one
                                                            </button>
                                                          )}
                                                          <button
                                                            className="btn btn-link"
                                                            onClick={() =>
                                                              handleRemoveSub(
                                                                index
                                                              )
                                                            }
                                                          >
                                                            Remove from{" "}
                                                            {c.ItemTitle}
                                                          </button>
                                                        </label>
                                                      </div>
                                                      <div className="row">
                                                        <label>
                                                          <button
                                                            className="btn btn-danger"
                                                            onClick={() =>
                                                              handleRemoveSubItem(
                                                                i,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            Remove
                                                          </button>
                                                        </label>
                                                      </div>
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                </Accordion>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </>
                            )}
                            <div className="col-6">
                              <div className="row">
                                <div className="col-3">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      itemID="bulkSelect"
                                      value={bulkSelect}
                                      onChange={handleBulkSelect}
                                      checked={bulkSelect}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="bulkSelect"
                                    >
                                      Bulk Select
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {selectedItems.length == 0 &&
                                    selectedSubItems.length == 0 ? (
                                    <button className="btn btn-link" disabled>
                                      Remove Selected Items
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-link"
                                      onClick={handleRemoveItems}
                                    >
                                      Remove Selected Items
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="card-footer text-right">
                        {MenuID != 0 ? (
                          <button
                            className="btn btn-link"
                            onClick={handleDeleteMenu}
                          >
                            Delete Menu
                          </button>
                        ) : null}
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          {MenuID == 0 ? "Create Menu" : "Save Menu"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};

export default ManageMenu;
