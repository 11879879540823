import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'

const ListSliders = () => {
    const [sliders, setSliders] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const columns = [
        {
            name: 'ID',
            selector: row => row.SliderId,
            sortable: true,

        },
        {
            name: 'Slider Name',
            selector: row => row.SliderName,
            sortable: true,

        },
        {
            name: 'Published',
            selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
            sortable: true,

        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.ManageSlider === true ? (<NavLink to={"/Sliders/" + row.SliderId} className="btn btn-outline-secondary btn-sm" ><BiEdit /></NavLink>) : null}

                {permissions.PublishSlider === true ? (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => TooglePublishSlider(row.SliderId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}

            </div>,
        }
    ];


    async function TooglePublishSlider(id) {
        let response = await fetch(
            Variables.API_URL + "Slider/TooglePublishSlider/" + id,
            {
                method: "Put",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
                },
            }
        );
        let result = await response.json();
        if (response.ok) {
            toast.success(result);
            GetSliders();
        } else {
            toast.error(result);
        }
    }
    async function GetSliders() {
        const URL = Variables.API_URL + "Slider/GetSliders";
        const response = await fetch(URL)
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setSliders(listItems);
    }
    //#region Use Effect 
    useEffect(() => {
        GetSliders();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Sliders</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Sliders</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>List Sliders</h3>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-body">
                                <div className="text-end mb-3">
                                    {permissions.ManageSlider === true ? (<NavLink to='/Sliders/0' className='btn btn-primary'>Add Slider</NavLink>) : null}
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={sliders}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default ListSliders;