import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiEdit, BiShowAlt, BiHide, BiTrash } from 'react-icons/bi';
import { AiOutlineCheck, AiOutlineClose, AiOutlineRight } from 'react-icons/ai';
import ReactTooltip from "react-tooltip";
import Util from "../../Classes/Util";
import { Button, Modal } from "react-bootstrap";
const ListAttributes = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  let navigate = useNavigate();
  const [attributeName, setAttributeName] = useState("");
  const [attributeType, setAttributeType] = useState();
  const [listAttributes, setAttributes] = useState([]);
  const [id, setID] = useState()
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(0);
  async function GetAttributes() {
    const URL = Variables.API_URL + "Attributes/GetAttributes";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAttributes(listItems);
  }
  function IsValid() {
    if (attributeName !== "") {
      return true;
    }
    return false;
  }
  function CheckResponse(response) {
    if (response.ok) {
      toast.success("Attribute Added Successfully");
      setAttributeName("");
      setAttributeType(0);
      GetAttributes();

    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setAttributeName("");
    setAttributeType(0);
    setUpdate(false);
  };
  function HandleSubmit(e) {
    if (update == false) {
      if (IsValid()) {
        e.preventDefault();

        const URL = Variables.API_URL + "Attributes/AddAttribute";
        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            attribute1: attributeName,
            attributeTypeID: attributeType,
            IsPublished: false
          }),
        })
          .then((response) => CheckResponse(response));
      } else {
        toast.error("Please fill all required fields!");
      }
    }
    else {
      if (IsValid()) {
        e.preventDefault();

        const URL = Variables.API_URL + "Attributes/UpdateAttribute/" + id;
        fetch(URL, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            attribute1: attributeName,
            attributeTypeID: attributeType,
            AttributeId: id

          }),
        })
          .then((response) => {
            if (response.ok) {
              setUpdate(false)
              CheckResponse(response)

            }
          })
      } else {
        toast.error("Please fill all required fields!");
      }

    }
  }

  const TooglePublishAttribute = async (AttributeId) => {
    let response = await fetch(
      Variables.API_URL + "Attributes/TooglePublishAttribute/" + AttributeId,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetAttributes();
    } else {
      toast.error(result);
    }
  };

  const ToggleInclude = async (AttributeId) => {
    console.log(AttributeId)
    let response = await fetch(
      Variables.API_URL + "Attributes/ToggleIncludeInFilter/" + AttributeId,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetAttributes();
    } else {
      toast.error(result);
    }
  };

  function GetAttributesByID(attributeId) {
    setAttributeName(attributeId.Attribute1);
    setAttributeType(attributeId.AttributeTypeId);
    setID(attributeId.AttributeId)
    setUpdate(true)
  }
  const columns = [
    {
      name: "Attribute ID",
      selector: (row) => row.AttributeId,
      sortable: true,
      reorder: true,
    },
    {
      name: "Attribute Name",
      selector: (row) => row.Attribute1,
      sortable: true,
      reorder: true,
    },
    {
      name: "Attribute Type",
      selector: (row) =>
        row.AttributeTypeId == 1
          ? "Color"
          : row.AttributeTypeId == 2
            ? "Image"
            : "Button",
      sortable: true,
      reorder: true,
    },
    {
      name: "In Filter",
      selector: (row) => row.IncludeInFilter ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
      reorder: true,
    },
    {
      name: "",
      selector: (row) => (
        <>
          {permissions.AttributeValues === true ? (
            <NavLink to={"/Attributes/Value/" + row.AttributeId} className="configure">
              Configure Terms
            </NavLink>
          ) : null}
        </>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.PublishAttribute === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishAttribute(row.AttributeId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}
          {permissions.IncludeAttribute && (<button className="btn btn-outline-warning btn-sm ms-2" onClick={() => ToggleInclude(row.AttributeId)}> {row.IncludeInFilter ? <AiOutlineClose data-tip="Exclude" /> : <AiOutlineCheck datat-tip="Include" />} </button>)}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />

          {permissions.ManageAttribute === true ? (<button
            className="btn btn-outline-info btn-sm ms-2"
            onClick={() => GetAttributesByID(row)}
          >
            {" "}
            <BiEdit />{" "}
          </button>) : null}

          {permissions.DeleteAttribute && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.AttributeId)}><BiTrash /></button>)}

          <Modal
            show={row.AttributeId === show}
            onHide={() => setShow(0)}
          >
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the Attribute named <b>{row.Attribute1}</b>?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteAttribute(row.AttributeId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setShow(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  const DeleteAttribute = async (AttributeId) => {
    let response = await fetch(
      Variables.API_URL + "Attributes/DeleteAttribute/" + AttributeId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetAttributes();
    } else {
      toast.error("Please Check With Administrator");
    }
  };



  //#region Use Effect
  useEffect(() => {
    GetAttributes();
  }, []);
  //#endregion Use Effect
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Attributes</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Attributes</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {permissions.ManageAttribute && (
                  <div className="row">
                    <div className="col-4">
                      <form>
                        <label>Attribute Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Attribute Name"
                          value={attributeName}
                          onChange={(e) => setAttributeName(e.target.value)}
                        ></input>
                        <br />
                        <label>Attribute Type</label>
                        <select
                          className="form-control"
                          value={attributeType}
                          onChange={(e) => setAttributeType(e.target.value)}
                        >
                          <option value="0">Select Attribute Type</option>
                          <option value="1">Color</option>
                          <option value="2">Image</option>
                          <option value="3">Button</option>
                        </select>
                        <br />
                      </form>
                    </div>
                    <div className="col-8 text-end">
                      <button
                        onClick={(e) => handleCancel(e)}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                        onClick={HandleSubmit}
                      >
                        {update === true ? "update" : "save"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listAttributes}
                    pagination
                    className="table table-striped"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div>
  );
};
export default ListAttributes;
