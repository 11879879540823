import React, { useEffect, useState } from "react";
import "../assets/css/LoginCss/login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../Variations/Variations";
import { useNavigate, Link, Navigate } from "react-router-dom";
import Util from "../Classes/Util";
import { Input, Button } from "reactstrap";


function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [passwordFormatError, setPasswordFormatRequiredError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordRequiresError, setConfirmPasswordrequiredError] = useState(false);

  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,})");
  const navigate = new useNavigate();

  //handle password change
  const handlePassword = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setPasswordRequiredError(true);
    } else {
      setPasswordRequiredError(false);
    }
    setPassword(value);
  };

  // handle confirm password
  const handleConfirmPassword = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setConfirmPasswordError(true);
    }
    setConfirmPassword(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (password == null || password == "") {
      setPasswordFormatRequiredError(true);
      hasError = true;
    } 
    else if(!strongRegex.test(password)){
        setPasswordFormatRequiredError(true)
        hasError = true;
    }
    else{
        setPasswordFormatRequiredError(false);
        setPasswordFormatRequiredError(false);
    }

    if(confirmPassword == null || confirmPassword == ""){
        setConfirmPasswordrequiredError(true);
        hasError = true;
    }
    else if(password !== confirmPassword){
        setConfirmPasswordError(true);
        hasError = true;
    }
    else{
        setConfirmPasswordError(false);
        setConfirmPasswordrequiredError(false);
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  const Submit = async () => {
    var isValid = IsValid();
    if(isValid){
      let response = await fetch(Variables.API_URL + "User/UserResetPassword", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Email: email,
          OldPassword: oldPassword,
          NewPassword: password
        }),
      });
      let result = await response.json();
      //case of success
      if (response.status === 200) {
        toast.success(result);
        window.localStorage.clear();
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } 
      else{
        toast.error(result);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    }
    else{console.log("no")}
  };

  useEffect(() => {
    if("Email" in localStorage && "Password" in localStorage){
      setEmail(Util.decrypt(localStorage.getItem("Email")));
      setOldPassword(Util.decrypt(localStorage.getItem("Password")));
    }
    else{
      navigate("/");
    }
  }, [email,password]);

  console.log("email",email);
  console.log("pass",oldPassword  )

  return (
    <div>
      
        <div className="container-fluid LoginBackground">
      <div className="row justify-content-center  align-items-center loginRow">
        <div className="col-12  col-md-4 loginBox ">
          <h1 className="text-center"> Reset Password </h1>    
          <div className="col-12 col-md-12 emounAya">
          <form onSubmit={(e)=>e.preventDefault()}> 
          <p className="text-muted mb-4 text-center">
          <p className="text-muted mb-4 text-center">
                        Please choose a new password
                      </p>
                      </p>
                      <div className="mb-3">

                      <Input
                          id="NewPassword"
                          name="NewPassword"
                          placeholder="New Password"
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={handlePassword}
                        />
                        {passwordRequiredError && (
                          <p className="color-red text-center">
                            Password Required
                          </p>
                        )}
                        {passwordFormatError && (
                          <p className="color-red text-center">Password must  be at least eight characters, contain at least 1 lowercase, 1 uppercase, 1 numeric character</p>
                        )}

<Input
                          id="ConfirmPassword"
                          name="ConfirmPassword"
                          placeholder="Confirm Password"
                          type="password"
                          className="form-control "
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                        />
                        {confirmPasswordError && (
                          <p className="color-red text-center">
                            Confirm Password not match
                          </p>
                        )}
                        {confirmPasswordRequiresError && (
                          <p className="color-red text-center">
                            Please Confirm your Password
                          </p>
                        )}
                      </div>
                      <div className="d-grid gap-2 mt-2">
                        <Button
                         className="btn btn-primary d-block text-center text-uppercase mb-2 rounded-pill shadow-sm"

                          onClick={() => Submit()}
                        >
                          Submit
                        </Button>
                      </div>
                        </form>
            </div> 
        </div>
        
      </div>
    </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ResetPassword;
