import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'

const AllSlides = () => {
    const [slides, setSlides] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const columns = [
        {
            name: 'ID',
            selector: row => row.SlideId,
            sortable: true,

        },
        {
            name: 'Slide Name',
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'Published',
            selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
            sortable: true,
        },
        {
            name: 'Options',
            selector: row => <div>
                {permissions.ManageSlide === true ? (<NavLink to={"/Slides/" + row.SlideId} className="btn btn-outline-secondary btn-sm" ><BiEdit /></NavLink>) : null}
                {permissions.PublishSlide === true ? (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => TooglePublishSlide(row.SlideId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}

            </div>,
        }
    ];




    async function TooglePublishSlide(id) {
        let response = await fetch(
            Variables.API_URL + "Slide/TooglePublishSlide/" + id,
            {
                method: "Put",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "https://api.smallinsize.com/api/",
                },
            }
        );
        let result = await response.json();
        if (response.ok) {
            toast.success(result);
            GetSlides();
        } else {
            toast.error(result);
        }
    }

    async function GetSlides() {
        const URL = Variables.API_URL + "Slide/GetSlides";
        const response = await fetch(URL)
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setSlides(listItems);
    }
    //#region Use Effect 
    useEffect(() => {
        GetSlides();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Slides</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Slides</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>List Slides</h3>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-body">
                                <div className="text-end mb-3">
                                    {permissions.ManageSlide === true ? (<NavLink to='/Slides/0' className='btn btn-primary'>Add Slide</NavLink>) : null}
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={slides}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default AllSlides;