import React from "react";
import Dashboard from "../Components/Dashboard";
import ListCategory from "../Components/Category/ListCategory";
import ManageCategory from "../Components/Category/ManageCategory";
import ListProducts from "../Components/Products/ListProducts";
import ManageProducts from "../Components/Products/ManageProducts";
import ListAttributes from "../Components/Attributes/ListAttributes";
import ManageAttributes from "../Components/Attributes/ManageAttributes";
import ManageAttributesValues from "../Components/Attributes/ManageAttributesValues";
import Settings from "../Components/Settings/Settings";
import ListUsers from "../Components/Settings/ListUsers";
import ManageUsers from "../Components/Settings/ManageUsers";
import ListMedia from "../Components/Media/ListMedia";
import AddMedia from "../Components/Media/AddMedia";
import NotFound from "../Components/NotFound";
import Brands from "../Components/Brands/ListBrands";
import ManageBrand from "../Components/Brands/ManageBrands";
import { Route, Routes } from "react-router-dom";
import ListTags from "../Components/Tags/ListTags";
import ManageTags from "../Components/Tags/ManageTags";
import ListCustomers from "../Components/Customers/ListCustomers";
import AllPages from "../Components/Pages/AllPages";
import ManagePages from "../Components/Pages/ManagePages";
import AllMenus from "../Components/Menus/AllMenus";
import ManageMenu from "../Components/Menus/ManageMenu";
import ListSliders from "../Components/Slider/ListSliders";
import ManageSlider from "../Components/Slider/ManageSlider";
import AllSlides from "../Components/Slides/AllSlides";
import ManageSlide from "../Components/Slides/ManageSlide";
import AllWidgets from "../Components/Widgets/AllWidgets";
import ManageWidget from "../Components/Widgets/ManageWidget";
import CustomerDetails from "../Components/Customers/CustomerDetails";
import ListRoles from "../Components/Settings/Roles/ListRoles";
import ManageRole from "../Components/Settings/Roles/ManageRole";
import Groups from "../Components/groups/Groups";
import ManageGroup from "../Components/groups/ManageGroup";
import ManageCoupons from "../Components/Coupon/ManageCoupons";
import Coupons from "../Components/Coupon/Coupons";
import CouponGroups from "../Components/Coupon/CouponGroups";
import ManageCouponGroup from "../Components/Coupon/ManageCouponGroup";
import ListOrder from "../Components/Orders/ListOrder";
import CartPriceRules from "../Components/Cart Price Rule/CartPriceRules";
import ManageCartPriceRule from "../Components/Cart Price Rule/ManageCartPriceRule";
import SendersList from "../Components/Shipment Sender Detail/SendersList";
import ManageSenders from "../Components/Shipment Sender Detail/ManageSenders";
import Materials from "../Components/Material/Materials";
import ManageMaterial from "../Components/Material/ManageMaterial";
import ManageSmtp from "../Components/SMTP/ManageSmtp";
import ListSizeChart from "../Components/SizeChart/ListSizeChart";
import ManageUniversalSizeChart from "../Components/SizeChart/ManageUniversalSizeChart";
import ListCategoriesForSizeChart from "../Components/SizeChart/ListCategoriesForSizeChart";
import Measurements from "../Components/Measurment/Measurements";
import ManageMeasurement from "../Components/Measurment/ManageMeasurement";
import ManageSizeChart from "../Components/SizeChart/ManageSizeChart";
import ListNotification from "../Components/Notification/ListNotification";
import Contents from "../Components/Content/Contents";
import ManageContent from "../Components/Content/ManageContent";
import Countries from "../Components/Country/Countries";
import ManageCountry from "../Components/Country/ManageCountry";
import ContactUs from "../Components/Notification/ContactUs";
// import Login from "../Components/Login";
const AppRoutes = (props) => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="Category" element={<ListCategory />} />
      <Route path="Category/:CategoryID" element={<ManageCategory />} />
      <Route path="Products" element={<ListProducts />} />
      <Route path="Products/:ProductID" element={<ManageProducts />} />
      <Route path="/Customers" element={<ListCustomers />} />
      <Route path="Brand" element={<Brands />} />
      <Route path="Brand/:BrandID" element={<ManageBrand />} />
      <Route path="Attributes" element={<ListAttributes />} />
      <Route path="Attributes/:AttributeID" element={<ManageAttributes />} />
      <Route
        path="Attributes/Value/:AttributeID"
        element={<ManageAttributesValues />}
      />
      <Route path="Settings" element={<Settings />} />
      <Route path="Users" element={<ListUsers />} />
      <Route path="Users/:UserID" element={<ManageUsers />} />
      <Route path="Tags" element={<ListTags />} />
      <Route path="Tags/:TagsID" element={<ManageTags />} />
      <Route path="Media" element={<ListMedia />} />
      <Route path="Media/:MediaID" element={<AddMedia />} />
      <Route path="Pages" element={<AllPages />} />
      <Route path="Pages/:PageID" element={<ManagePages />} />
      <Route path="Menus" element={<AllMenus />} />
      <Route path="Menus/:MenuID" element={<ManageMenu />} />
      <Route path="Coupons/:CouponID" element={<ManageCoupons />} />
      <Route path="/Coupons" element={<Coupons />} />
      <Route path="Sliders" element={<ListSliders />} />
      <Route path="Sliders/:SliderID" element={<ManageSlider />} />
      <Route path="Slides" element={<AllSlides />} />
      <Route path="Slides/:SlideID" element={<ManageSlide />} />
      <Route path="/Widgets" element={<AllWidgets />} />
      <Route path="/Widgets/:WidgetID" element={<ManageWidget />} />
      <Route path="/ViewCustomer/:CustomerId" element={<CustomerDetails />} />
      <Route path="/Roles" element={<ListRoles />} />
      <Route path="/Roles/:RoleID" element={<ManageRole />} />
      <Route path="/Groups" element={<Groups />} />
      <Route path="/Groups/:GroupID" element={<ManageGroup />} />
      <Route path="/CouponGroups" element={<CouponGroups />} />
      <Route
        path="/CouponGroups/:CouponGroupID"
        element={<ManageCouponGroup />}
      />
      <Route path="/Orders" element={<ListOrder />} />
      <Route path="/PriceRules" element={<CartPriceRules />} />
      <Route path="/PriceRules/:ID" element={<ManageCartPriceRule />} />
      <Route path="/Senders" element={<SendersList />} />
      <Route path="/Senders/:SenderID" element={<ManageSenders />} />
      <Route path="/Materials" element={<Materials />} />
      <Route path="/Materials/:MaterialID" element={<ManageMaterial />} />
      <Route path="/Smtps" element={<ManageSmtp />} />

      <Route exact path="/size-chart" element={<ListSizeChart />} />
      <Route
        exact
        path="/universal-size-chart/category"
        element={<ListCategoriesForSizeChart />}
      />
      <Route
        exact
        path="/size-chart/category"
        element={<ListCategoriesForSizeChart />}
      />
      <Route
        exact
        path="/size-chart/:sizechartid/category/:categoryid"
        element={<ManageSizeChart />}
      />
      <Route
        exact
        path="/universal-size-chart/:sizechartid/category/:categoryid"
        element={<ManageUniversalSizeChart />}
      />

      <Route path="/Measurements" element={<Measurements />} />
      <Route
        path="/Measurements/:MeasurementID"
        element={<ManageMeasurement />}
      />
      <Route
        path="/notification"
        element={
          <ListNotification
            notifications={props.notifications}
            setNotifications={props.setNotifications}
          />
        }
      />
      <Route path="/ContactUs" element={<ContactUs contactUs={props.contactUs} setContactUs={props.setContactUs}/>} />
      <Route path="/Contents" element={<Contents/>}/>
      <Route path="/Contents/:ContentID" element={<ManageContent/>}/>
      <Route path="/Countries" element={<Countries/>}/>
      <Route path="/Countries/:CountryCode" element={<ManageCountry/>}/>
    </Routes>
  );
};
export default AppRoutes;
