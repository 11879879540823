import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Util from "../../Classes/Util";
import axios from "axios";
import { PaginatedList } from "react-paginated-list";
import Dropzone from "react-dropzone";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
const ManageBrand = () => {
  //#region Variables
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  let { BrandID } = useParams();
  let navigate = useNavigate();
  const [brandName, setBrandName] = useState("");
  const [parentID, setParentID] = useState();
  const [isFeatured, setIsFeatured] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [brandDetails, setBrandDetails] = useState();
  const [parentBrands, setParentBrands] = useState([]);
  const [thumbnail, setThumbnail] = useState(0);
  const [Media, setMedia] = useState([]);
  const [showingThumbnail, setShowingThumbnail] = useState("");
  const [show, setShow] = useState(false);
  const [filteredData, setFilteredData] = useState(Media);

  //#endregion Variables

  //#region Handlers

  function handleImage(id, image) {
    setThumbnail(id);
    setShowingThumbnail(image);
    setShow(false);
  }

  const handleDrop = async (files) => {
    debugger;
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData
            );
            if (res.data !== "") {
              await fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  image: res.data,
                  imageTitle: files[i].name.split(".").slice(0, -1).join("."),
                  altText: "",
                  description: "",
                  caption: "",
                }),
              }).then((Response) => {
                if (Response.ok) {
                  fetchMedia();
                } else {
                  toast.error(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = Media.filter((data) => {
      return data.ImageTitle.search(value) != -1;
    });
    setFilteredData(result);
  };
  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
        setFilteredData(Result);
      });
  }
  function IsValid() {
    if (brandName == "") {
        toast.error("Please fill all required fields!");
      return false;
    }
    if(thumbnail==0){
toast.error("Please choose an imgae");
return false;
    }
    return true;
  }
  function HandleSubmit(e) {
 e.preventDefault();
    if (IsValid()) {
      e.preventDefault();
      if (BrandID == 0) {
        const addBrand = async () => {
          let response = await fetch(Variables.API_URL + "Brand/AddBrand", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Brand1: brandName,
              ParentId: parentID,
              IsFeatured: isFeatured,
              IsPublished: isPublished,
              Description: brandDetails,
              Thumbnail: thumbnail,
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            setTimeout(() => {
              navigate("/Brand");
            }, 600);
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await addBrand())();
        }, 100);
      } else {
        console.log(JSON.stringify({
            Brand1: brandName,
            ParentId: parentID,
            IsFeatured: isFeatured,
            IsPublished: isPublished,
            Description: brandDetails,
            Thumbnail: thumbnail,
          }))
        const updateBrand = async () => {
          let response = await fetch(
            Variables.API_URL + "Brand/UpdateBrand/" + BrandID,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                Brand1: brandName,
                ParentId: parentID,
                IsFeatured: isFeatured,
                IsPublished: isPublished,
                Description: brandDetails,
                Thumbnail: thumbnail,
              }),
            }
          );
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            setTimeout(() => {
              navigate("/Brand");
            }, 600);
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await updateBrand())();
        }, 100);
      }
    }
  }

  //#endregion Handlers

  //#region  getters
  async function GetBrands(brandID) {
    const URL = Variables.API_URL + "Brand/GetBrand/" + brandID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setBrandName(listItems.Brand1);
    setParentID(listItems.ParentId);
    setIsPublished(listItems.IsPublished);
    setIsFeatured(listItems.IsFeatured);
    setThumbnail(listItems.Thumbnail);
    setShowingThumbnail(listItems.ShowingThumbnail);
    setBrandDetails(
      listItems.Description === null ? "" : listItems.Description
    );
    console.log(listItems)
  }
  async function GetParentBrands() {
    const URL = Variables.API_URL + "Brand/GetParentBrands/"+BrandID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setParentBrands(listItems);
  }
  //#endregion getters

  //#region Use Effect
  useEffect(() => {
    if (BrandID != 0) {
      GetBrands(BrandID);
    }
    GetParentBrands();
    fetchMedia();
  }, []);
  //#endregion Use Effect
  console.log(parentID);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{BrandID == "0" ? "Add" : "Update"} Brand</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Brand">Brands <AiOutlineRight /></Link></li>
              <li>Manage Brand</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form>
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8">
                      <h3>{brandName}</h3>
                    </div>
                    <div className="col-4 text-right">
                      <NavLink to="/Brand" className="btn btn-secondary">
                        Cancel
                      </NavLink>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                        onClick={HandleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                        <label>Brand Name</label>
                        <input
                          className="form-control required"
                          placeholder="Brand Name"
                          required
                          value={brandName}
                          onChange={(e) => setBrandName(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                        <label>Parent</label>
                        <select
                          className="form-control"
                          onChange={(e) => setParentID(e.target.value)}
                          value={parentID}
                        >
                          <option key="0" value="0">
                            Root Brand
                          </option>
                          {parentBrands.map((x) => (
                            <option key={x.BrandId} value={x.BrandId}>
                              {x.Brand1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                        <label>Products Order</label>
                        <select className="form-control">
                          <option key="0" value="0">
                            Order Products By
                          </option>
                        </select>
                      </div>
                      <div className="col-2 mt-2">
                        <input
                          type="checkbox"
                          id="isFeatured"
                          onChange={(e) =>
                            setIsFeatured(
                              e.target.checked == true ? true : false
                            )
                          }
                          checked={isFeatured}
                        />
                        <label htmlFor="isFeatured" className="ml-3">
                          Featured
                        </label>
                        <br />
                        {permissions.PublishBrand === true ? (
                          <>
                            <input
                              type="checkbox"
                              id="isPublished"
                              onChange={(e) =>
                                setIsPublished(
                                  e.target.checked == true ? true : false
                                )
                              }
                              checked={isPublished}
                            />
                            <label htmlFor="isPublished" className="ml-3">
                              Published
                            </label>
                          </>
                        ) : null}
                      </div>
                      <div className="col-12 mt-2">
                        <label>Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={brandDetails}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setBrandDetails(data);
                          }}
                          // onBlur={(event, editor) => {
                          //     console.log('Blur.', editor);
                          // }}
                          // onFocus={(event, editor) => {
                          //     console.log('Focus.', editor);
                          // }}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-12 mt-2">
                        <label> Brand Image</label> <br />
                        <Button
                          className="me-2 btn btn-secondary btn-sm "
                          onClick={() => setShow(true)}
                        >
                          Add Brand Image
                        </Button>
                        <Modal show={show} onHide={() => setShow(false)}>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Add images to product gallery
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Tabs
                              defaultActiveKey="Library"
                              id="uncontrolled-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="Upload" title="Upload File">
                                <div className="row mt-5">
                                  <div className="row">
                                    <Dropzone
                                      onDrop={handleDrop}
                                      accept="image/jpeg,image/png,image/jpg,image/svg"
                                      style={{ height: 500 }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p style={{ height: 500 }}>
                                            Drag'n'drop images, or click to
                                            select files
                                          </p>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </div>
                                  <div className="row">
                                    <br />
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="Library" title="Media Library">
                                <div className="col-12">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <div className="col-3">
                                        <input
                                          type="search"
                                          className="form-control"
                                          onChange={(e) => handleSearch(e)}
                                          placeholder={"Search media by name"}
                                        />
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="row">
                                    <PaginatedList
                                      list={filteredData}
                                      itemsPerPage={18}
                                      displayRange={2}
                                      showPrev={false}
                                      showNext={false}
                                      renderList={(list) => (
                                        <>
                                          <div className="row">
                                            {list.map((image, id) => {
                                              return (
                                                <div
                                                  className="col-2"
                                                  key={image.MediaId}
                                                >
                                                  <div className="imagecontainer">
                                                    <div className="form-check">
                                                      <button
                                                        className="btn btn-light"
                                                        onClick={() =>
                                                          handleImage(
                                                            image.MediaId,
                                                            image.Image
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          alt="media"
                                                          className="form-control"
                                                          src={
                                                            Variables.API_PUBLISHED +
                                                            image.Image
                                                          }
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-lg-12 mt-2 text-center">
                        {thumbnail !== 0 ? (
                          <img
                            className="form-control"
                            style={{ width: "300px", margin: "auto" }}
                            src={Variables.API_PUBLISHED + showingThumbnail}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default ManageBrand;
