
import react, { useState,useEffect,useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Variations/AppRoutes";
import PublicRoutes from "./Variations/PublicRoutes";
import SideBar from "./Variations/SideBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "./Classes/Util";
import { Variables } from "./Variations/Variations";
import './Styles.css'
import Notifier from "react-desktop-notification";

function App() {


  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [permissions,setPermissions] = useState({});
  const [notifications, setNotifications] = useState([]);
  const newNotifications = useRef([]);
  const[contactUs,setContactUs]=useState([]);
  const newContactUs=useRef([]);
  const CheckLoginToken = async () => {
    
    //fetch
    let response = await fetch(Variables.API_URL + "User/CheckUserLoginToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: Util.decrypt(localStorage.getItem("Email")),
        token: Util.decrypt(localStorage.getItem("LoginToken")),
      }),
    });
    let result = await response.json();
    //case of success
    if (response.status === 200) {
      localStorage.setItem("Email", Util.encrypt(result.Email));
      localStorage.setItem("LoginToken", Util.encrypt(result.LoginToken));
      localStorage.setItem("Permissions", Util.encrypt(result.Permissions));
      localStorage.setItem("UserId", Util.encrypt(result.UserId));
      localStorage.setItem("UserName", Util.encrypt(result.UserName));

      setPermissions(Util.decrypt(localStorage.getItem("Permissions")))
      setIsAuthenticated(true);
    
    }
    else{
      setIsAuthenticated(false)
    }
  };


  const GetNotifications = async () => {
      let offset = 0,
        date = "",
        newDate = "";
      const response = await   fetch( Variables.API_URL+
         "Notification/GetNotificationsWithTypes",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
        }
      );
      let result = await response.json();
      result.forEach((notification) => {
        offset = new Date(notification.CreatedDate).getTimezoneOffset();
        date = new Date(notification.CreatedDate);
        newDate = new Date(date.getTime() - offset * 60 * 1000);
        notification.CreatedDate = newDate;
      });
      setNotifications(result);
      if (
        newNotifications.current.length != 0 &&
        result.length !== newNotifications.current.length
      )
        SendNotification(
          result,
          result.length - newNotifications.current.length
        );
      newNotifications.current = result;
      setTimeout(() => {
        GetNotifications();
      }, 5000);
    
  };
  const GetContactUs = async () => {
    let offset = 0,
      date = "",
      newDate = "";
    const response = await   fetch( Variables.API_URL+
       "Notification/GetContactUsMessages",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    result.forEach((notification) => {
      offset = new Date(notification.CreatedDate).getTimezoneOffset();
      date = new Date(notification.CreatedDate);
      newDate = new Date(date.getTime() - offset * 60 * 1000);
      notification.CreatedDate = newDate;
    });
    setContactUs(result);
    if (
      newContactUs.current.length != 0 &&
      result.length !== newContactUs.current.length
    )
      SendContactUs(
        result,
        result.length - newContactUs.current.length
      );
    newContactUs.current = result;
    setTimeout(() => {
      GetContactUs();
    }, 5000);
  
};

  const SendNotification = (Notifications, Length) => {
    for (let index = 0; index < Length; index++) {
      Notifier.start(
        Notifications[index].Title,
        Notifications[index].Body,
        "notification",
        "https://api.smallinsize.com/Uploads/Images/07_05_2022/bae1c853-b4af-48a2-b371-5022ad02da19.png"
      );
    }
  };
  const SendContactUs = (Notifications, Length) => {
    for (let index = 0; index < Length; index++) {
      Notifier.start(
        Notifications[index].Title,
        Notifications[index].Body,
        "ContactUs",
        "https://api.smallinsize.com/Uploads/Images/07_05_2022/bae1c853-b4af-48a2-b371-5022ad02da19.png"
      );
    }
  };
  useEffect(() => {
    if("Email" in localStorage && "LoginToken" in localStorage){
        CheckLoginToken();
        GetNotifications();
        GetContactUs();
    }else{
      setIsAuthenticated(false)
    }
}, [isAuthenticated]);

  return (
    <div className="App">
      <BrowserRouter>
        {isAuthenticated ===true&& (
          <react.Fragment>
            <SideBar setIsAuthenticated={setIsAuthenticated} permissions={permissions} notifications={notifications} setNotifications={setNotifications} contactUs={contactUs} setContactUs={setContactUs}/>
            <AppRoutes isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated} notifications={notifications} setNotifications={setNotifications} contactUs={contactUs} setContactUs={setContactUs}/>
          </react.Fragment>
        )}

        {isAuthenticated ===false&& (
          <react.Fragment>
            <PublicRoutes isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated}/>
          </react.Fragment>
        )}
         {/* <SideBar/>
        <AppRoutes/> */}
      </BrowserRouter> 

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
      />
    </div>
  );
}
// else{
//   return(
//     <div>
//       <h1>You are not Authorized</h1>
//     </div>
//   )
// }
// }

export default App;
