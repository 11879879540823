import React, { useEffect, useState } from "react";
import { Variables } from "../../Variations/Variations";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import SlideGallery from "./SlideGallery";
import { toast } from "react-toastify";
import Util from "../../Classes/Util";
import { AiOutlineRight } from 'react-icons/ai'

const ManageSlider = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  let { SlideID } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [publishDate, setPublishDate] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [media, setMedia] = useState("");
  const [showingMedia, setShowingMedia] = useState("");
  const [sliders, setSliders] = useState([]);
  const [selectedSliders, setSelectedSliders] = useState([]);
  const [subtitle, setSubtitle] = useState("");
  const [url, setUrl] = useState("");

  function handleContent(e) {
    setShowContent(e.target.checked);
  }
  function IsValid() {
    if (title === "") {
      toast.error("Slide Name Required");
      document.getElementById("title").focus();
      return false;
    }
    if (media === "") {
      toast.error("Please choose a media for your slide");
      document.getElementById("media").focus();
    }
    return true;
  }

  function handlePublish(e) {
    if (e.target.value !== "") {
      setPublishDate(e.target.value);
      setIsPublished(true);
    }
  }

  function handleSubmit() {
    if (IsValid()) {
      if (SlideID == 0) {
        const addSlider = async () => {
          let response = await fetch(Variables.API_URL + "Slide/AddSlide", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Title: title,
              PublishDate: publishDate,
              IsPublished: isPublished,
              Media: media,
              ShowContent: showContent,
              Subtitle: subtitle,
              Url: url,
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            const URL =
              Variables.API_URL + "Slide/AssignSlidersToSlide/" + result;
            fetch(URL, {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(
                selectedSliders.map((item) => ({
                  SlideID: result,
                  SliderId: item.SliderId,
                }))
              ),
            }).then((response) => {
              if (response.ok) {
                toast.success("Added Successfully");
                navigate("/Slides");
              } else {
                toast.error(response);
              }
            });
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await addSlider())();
        }, 100);
      } else {
        const URL = Variables.API_URL + "Slide/UpdateSlide/" + SlideID;
        fetch(URL, {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Title: title,
            PublishDate: publishDate,
            IsPublished: isPublished,
            Media: media,
            ShowContent: showContent,
            Subtitle: subtitle,
            Url: url,
          }),
        }).then((response) => {
          if (response.ok) {
            const URL =
              Variables.API_URL + "Slide/AssignSlidersToSlide/" + SlideID;
            fetch(URL, {
              method: "Post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(
                selectedSliders.map((item) => ({
                  SlideID: SlideID,
                  SliderId: item.SliderId,
                }))
              ),
            }).then((response) => {
              if (response.ok) {
                toast.success("Updated Successfully");
                navigate("/Slides");
              } else {
                toast.error(response);
              }
            });
          } else {
            toast.error(response);
          }
        });
      }
    }
  }

  async function GetSlide() {
    const URL = Variables.API_URL + "Slide/GetSlide/" + SlideID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setIsPublished(listItems.IsPublished);
    setPublishDate(listItems.PublishDate);
    setMedia(listItems.Media);
    setShowingMedia(listItems.Image);
    setShowContent(listItems.ShowContent);
    setTitle(listItems.Title);
    setSubtitle(listItems.Subtitle);
    setUrl(listItems.Url);
  }
  async function GetSliders() {
    const URL = Variables.API_URL + "Slider/GetSliders";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSliders(listItems);
    if (listItems.length != 0) {
      if (SlideID != 0) {
        GetAssignedSliders();
      }
    }
  }

  async function GetAssignedSliders() {
    const URL = Variables.API_URL + "Slide/GetAssignedSliders/" + SlideID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSelectedSliders(listItems);
  }
  function handleSliders(e) {
    setSelectedSliders(e);
  }

  useEffect(() => {
    if (SlideID != 0) {
      GetSlide();
    }
    GetSliders();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{SlideID == "0" ? "Add" : "Manage " + title + " Slide"}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Slides">Slides <AiOutlineRight /></Link></li>
              <li>Manage Slide</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{SlideID == "0" ? "Add" : "Update"} Slide</h3>
                  </div>

                </div>
              </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-end">
                    <NavLink to="/Slides" className="btn btn-secondary">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary m-1 "
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-12">
                        <div className="row mb-4">
                          <div className="col-12">
                            <label className="mb-2">
                              Slide Name <span className="required">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="title "
                              onChange={(e) => setTitle(e.target.value)}
                              value={title !== "" ? title : ""}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12">
                            <label className="mb-2">Slide Subtitle </label>
                            <input
                              className="form-control"
                              id="subtitle "
                              onChange={(e) => setSubtitle(e.target.value)}
                              value={subtitle !== "" ? subtitle : ""}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col-12 ">
                            <label className="mb-2">Slide Url </label>
                            <input
                              className="form-control"
                              id="url "
                              onChange={(e) => setUrl(e.target.value)}
                              value={url !== "" ? url : ""}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col-12">
                            <SlideGallery
                              setMedia={setMedia}
                              setShowingMedia={setShowingMedia}
                            />
                          </div>
                        </div>
                        {showingMedia !== "" ? (
                          <div className="row">
                            <div className="col-12">
                              <img
                                src={Variables.API_PUBLISHED + showingMedia}
                                className="img-thumbnail"
                              />
                            </div>
                          </div>
                        ) : null}

                        {permissions.PublishSlide === true ? (
                          <div className="row mb-4">
                            <div className="col-12">
                              <label className="mb-2">Published Date</label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                value={publishDate}
                                onChange={(e) => handlePublish(e)}
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="row mb-4">
                          <div className="col-12">
                            <label className="mb-2">Sliders</label>
                            <Multiselect
                              options={sliders}
                              selectedValues={selectedSliders}
                              displayValue="SliderName"
                              onSelect={handleSliders}
                              onRemove={handleSliders}
                              placeholder="Select slides"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={showContent}
                                id="flexCheckDefault"
                                onChange={(e) => handleContent(e)}
                                checked={showContent}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckDefault"
                              >
                                show Content
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSlider;
